import styled from 'styled-components';
import {Heading, Button } from '@lmig/lmds-react';

export const StyledOptionCard = styled.div`
    border: 1px solid black;
    border-radius: 12px;
    padding-left: 43px;
    padding-right: 43px;
    width: 411px;
    height: 293px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledHeading = styled(Heading)`
    margin-top: 41px;
`

export const StyledButton = styled(Button)`
    width: 324px;
    height: 50px;
    margin-bottom: 53px;
`