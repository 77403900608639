import {
    Disclaimer, Footer as LMDSFooter
  } from '@lmig/lmds-react';

export const Footer = () => {
    return (
    <LMDSFooter>
        <hr className="horizontal-rule" />
        <Disclaimer>
        <p>{'© 2023 Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116'}</p>
        </Disclaimer>
    </LMDSFooter>
    )
}