import styled from 'styled-components';
import { Heading } from '@lmig/lmds-react';
import colorTokens from '@lmig/lmds-tokens/dist/json/core/color.json';

const lmdsColors = colorTokens.lmig.color;
const { gray29 } = lmdsColors;

export const StyledHeading = styled(Heading)`
    margin-top: 108px;
`;

export const StyledAddressBlurb = styled.div`
    padding-bottom: 32px;
    margin-bottom: 24px;
`;

export const StyledAddressDisplayContainer = styled.div`
    padding-bottom: 12px;
    margin-bottom: 40px;
`;

export const StyledButtonsContainer = styled.div`
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid ${gray29};
    display: flex;
    justify-content: space-between;
`;
