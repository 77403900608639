import { useState, useEffect } from "react";
import { Heading, BodyText, Checkbox, Accordion, AccordionTab, Button } from "@lmig/lmds-react";
import { GoGreenContainer, GoGreenIconContainer, DirectDepositSectionContainer, CheckSectionContainer, ButtonContainer, CheckboxContainer} from "./SelectPaymentType.styles";
import { GoGreenIcon } from "./assets/GoGreenIcon";
import { CheckConfirmationModal } from "./components/CheckConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { useAppStore } from "../../store/store";
import { PAYMENT_TYPES } from "../../store/store.types";
import Stepper from '../../components/stepper/Stepper';
import { stepLabels } from "../../components/stepper/steps-data";
import { PAGES } from "../../utilities/consts";

export const SelectPaymentType = () =>{

    const navigate = useNavigate();
    const {selectedPaymentType, setSelectedPaymentType} = useAppStore();
    const {DIRECT_DEPOSIT, CHECK, NOT_SELECTED} = PAYMENT_TYPES;
    const [isCheckChecked, setIsCheckChecked] = useState(selectedPaymentType === CHECK);
    const [isDirectDepositChecked, setIsDirectDepositChecked] = useState(selectedPaymentType === DIRECT_DEPOSIT);
    const [shouldDisplayCheckModal, setShouldDisplayCheckModal] = useState(false);
    const { TERMS } = PAGES;

    useEffect(() => {
        if (isCheckChecked || isDirectDepositChecked){
            setSelectedPaymentType(isDirectDepositChecked ? DIRECT_DEPOSIT : CHECK);
        } else {
            setSelectedPaymentType(NOT_SELECTED);
        }
      }, [isCheckChecked, isDirectDepositChecked, CHECK, DIRECT_DEPOSIT, NOT_SELECTED, setSelectedPaymentType]);
    
    const handleOnChangeDirectDeposit = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDirectDepositChecked(e.target.checked);
        e.target.checked && setIsCheckChecked(false);
    }

    const handleOnChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckChecked(e.target.checked);
        e.target.checked && setIsDirectDepositChecked(false);
    }

    const handleContinue = () => {
        if(isCheckChecked){
            setShouldDisplayCheckModal(true);
        } else {
            navigate(`/${TERMS}`);
        }
    }   
    
    return (
        <>
            <Stepper activeStepLabel={stepLabels.PAYMENT_METHOD}/>
            <Heading type="h3-light">{'Select the way you would like to be paid'}</Heading>
            <GoGreenContainer>
                <Heading type="h3-light">{'Go Green with Direct Deposit!'}</Heading>
                <GoGreenIconContainer>
                    <GoGreenIcon />
                </GoGreenIconContainer>
            </GoGreenContainer>

            <DirectDepositSectionContainer>
                <Heading type="h4-medium">{'Direct Deposit'}</Heading>
                <BodyText>{'Direct deposit payments are quicker, safer, and more sustainable. They typically appear in your account in 2-3 business days. Reduce your carbon footprint and go green!'}</BodyText>
                <CheckboxContainer>
                    <Checkbox
                        name="selectDirectDeposit"
                        labelVisual={""}
                        labelA11y={"Sign me up for Direct Deposit"}
                        data-testid="selectDirectDeposit"
                        checked={isDirectDepositChecked}
                        onChange={handleOnChangeDirectDeposit}
                    />
                    <BodyText type="lead">{"Sign me up for Direct Deposit"}</BodyText>
                 </CheckboxContainer>
            </DirectDepositSectionContainer>
            <CheckSectionContainer>
                <Accordion hasFullWidthContent={true} as="h6">
                    <AccordionTab as="h6" labelVisual="Paper check by mail">
                        <BodyText>{'Paper checks will be slower than direct deposit, and can take up to 15 business days to arrive.'}</BodyText>
                        <Checkbox
                            name="selectCheck"
                            labelVisual="Sign me up for paper check by mail"
                            data-testid="selectCheck"
                            checked={isCheckChecked}
                            onChange={handleOnChangeCheck}
                        />
                    </AccordionTab>
                </Accordion>
            </CheckSectionContainer>
            <ButtonContainer>
                <Button
                    type="submit"
                    disabled={(!isCheckChecked && !isDirectDepositChecked)}
                    variant="primary"
                    aria-label='Continue'
                    onClick={() => handleContinue()}
                    >
                        {'Continue'}
                </Button>
            </ButtonContainer>
            <CheckConfirmationModal 
                isOpen={shouldDisplayCheckModal} 
                setIsOpen={setShouldDisplayCheckModal} 
                handleConfirm={ ()=> navigate(`/${TERMS}`)} 
            />
        </>
    );
}
