
export const AddressDocumentSvg = () => {
    return (
        <svg width="125" height="75" viewBox="0 0 125 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="125" height="75" rx="4" fill="#C0ECEF"/>
            <rect x="99.3242" y="8.10938" width="13.5135" height="16.2162" fill="white"/>
            <line x1="37.8359" y1="39.0391" x2="112.16" y2="39.0391" stroke="#707070" stroke-width="3"/>
            <line x1="8.78516" y1="10.9883" x2="44.596" y2="10.9883" stroke="#707070"/>
            <line x1="37.8359" y1="49.8516" x2="112.16" y2="49.8516" stroke="#707070" stroke-width="3"/>
            <line x1="8.78516" y1="16.3906" x2="44.596" y2="16.3906" stroke="#707070"/>
            <line x1="37.8359" y1="60.6641" x2="112.16" y2="60.6641" stroke="#707070" stroke-width="3"/>
            <line x1="8.78516" y1="21.7969" x2="44.596" y2="21.7969" stroke="#707070"/>
        </svg>
    )
}