import { StepProps } from "./components/Step";

export const stepLabels = {
    IDENTITY_VERIFICATION: 'Identity Verification',
    PAYMENT_METHOD: 'Payment Method',
    AGREEMENTS: 'Agreements',
    FINANCIAL_INFORMATION: 'Financial Information',
    PAYMENT_LOCATION: 'Payment Location'
} as const;

export type StepLabel = typeof stepLabels[keyof typeof stepLabels];

export const noSelectionSteps: StepProps[] = [
    {text: stepLabels.IDENTITY_VERIFICATION, order: 1},
    {text: stepLabels.AGREEMENTS, order: 2},
    {text: stepLabels.FINANCIAL_INFORMATION, order: 3},
]

export const ddeSteps: StepProps[] = [
    {text: stepLabels.IDENTITY_VERIFICATION, order: 1},
    {text: stepLabels.PAYMENT_METHOD, order: 2},
    {text: stepLabels.AGREEMENTS, order: 3},
    {text: stepLabels.FINANCIAL_INFORMATION, order: 4},
]

export const checkSteps: StepProps[] = [
    {text: stepLabels.IDENTITY_VERIFICATION, order: 1},
    {text: stepLabels.PAYMENT_METHOD, order: 2},
    {text: stepLabels.AGREEMENTS, order: 3},
    {text: stepLabels.PAYMENT_LOCATION, order: 4},
]