import { BRANDS, Brand } from "../store/store.types";

const {LIBERTY_MUTUAL, SAFECO} = BRANDS;

const brandsByDomain: Record<string, Brand> = {
    'lmig.com': LIBERTY_MUTUAL,
    'libertymutual.com': LIBERTY_MUTUAL,
    'safeco.com': SAFECO,
    'default': LIBERTY_MUTUAL,
}

export const getBrandFromUrl = (location: Location): Brand => {
    const key = Object.keys(brandsByDomain).find(domain => location.hostname.includes(domain)) || 'default';
    return(brandsByDomain[key]);
};