
import { StyledOptionCard, StyledButton, StyledHeading } from "./OptionCard.styles";

interface OptionCardProps {
    action: () => void;
    buttonText: string
    heading: string;
}

export const OptionCard = (props: OptionCardProps) => {

    const {action, heading, buttonText} = props;

    return (
        <StyledOptionCard>
            <StyledHeading type="h4">{heading}</StyledHeading>
            <StyledButton 
                onClick={()=>action()}
            >
                {buttonText}
            </StyledButton>
        </StyledOptionCard>
    );
}